import React from 'react';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <div className="flex flex-wrap justify-center lg:justify-start space-x-4">
            <HashLink
                className="relative px-4 py-2 text-[#1e3a8a] font-extrabold uppercase tracking-wide transition-transform duration-300 ease-in-out hover:text-yellow-400 hover:-translate-y-1"
                smooth
                to="/#about"
            >
                About
                <span className="absolute bottom-0 left-0 w-0 h-1 bg-[#1e3a8a] transition-all duration-300 ease-in-out hover:w-full"></span>
            </HashLink>
            <HashLink
                className="relative px-4 py-2 text-[#1e3a8a] font-extrabold uppercase tracking-wide transition-transform duration-300 ease-in-out hover:text-yellow-400 hover:-translate-y-1"
                smooth
                to="/#services"
            >
                Services
                <span className="absolute bottom-0 left-0 w-0 h-1 bg-[#1e3a8a] transition-all duration-300 ease-in-out hover:w-full"></span>
            </HashLink>
            <HashLink
                className="relative px-4 py-2 text-[#1e3a8a] font-extrabold uppercase tracking-wide transition-transform duration-300 ease-in-out hover:text-yellow-400 hover:-translate-y-1"
                to="/"
            >
                Portfolio
                <span className="absolute bottom-0 left-0 w-0 h-1 bg-[#1e3a8a] transition-all duration-300 ease-in-out hover:w-full"></span>
            </HashLink>
            <HashLink
                className="relative px-4 py-2 text-[#1e3a8a] font-extrabold uppercase tracking-wide transition-transform duration-300 ease-in-out hover:text-yellow-400 hover:-translate-y-1"
                to="/contact#contact"
            >
                Contact Us
            </HashLink>
        </div>
    )
}

export default NavLinks;
