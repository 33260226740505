import React from 'react';
import { FaLinkedin, FaTwitter, FaGithub } from 'react-icons/fa';

// Function to get initials from the name
const getInitials = (name) => {
    const names = name.split(' ');
    if (names.length === 1) {
        return names[0].charAt(0).toUpperCase();
    } else {
        return names[0].charAt(0).toUpperCase() + names[1].charAt(0).toUpperCase();
    }
}

const teamMembers = [
    {
        name: "Samir Aissaoui",
        role: "Fondateur & CEO",
        description: "Expert en technologies de l'information avec plus de 15 ans d'expérience dans le domaine.",
        image: "", // No image provided
        linkedin: "#",
        twitter: "#",
        github: "#"
    },
    {
        name: "Yasmine Ben Ali",
        role: "Directrice de l'Innovation",
        description: "Spécialiste en innovation technologique et gestion de projets.",
        image: "", // No image provided
        linkedin: "#",
        twitter: "#",
        github: "#"
    }
];

const Teams = () => {
    return (
        <div className="my-12 py-12 bg-gray-100" id="teams">
            <h2 className="my-6 text-center text-4xl text-blue-900 uppercase font-bold tracking-wider">Meet Our Team</h2>
            <div className="flex justify-center mb-8">
                <div className="w-32 border-b-4 border-blue-900"></div>
            </div>

            <div className="px-4">
                <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
                    {teamMembers.map((member, index) => (
                        <div key={index} className="bg-white transition-transform transform hover:scale-105 hover:shadow-lg overflow-hidden text-gray-700 rounded-lg shadow-lg p-6 flex flex-col items-center border border-gray-300">
                            <div className="w-32 h-32 mb-4 flex items-center justify-center overflow-hidden rounded-full border-4 border-blue-600 relative bg-gray-200">
                                {member.image ? (
                                    <img src={member.image} alt={member.name} className="w-full h-full object-cover" />
                                ) : (
                                    <span className="text-4xl font-semibold text-blue-600">
                                        {getInitials(member.name)}
                                    </span>
                                )}
                            </div>
                            <div className="text-center mb-4">
                                <h4 className="font-semibold text-xl text-gray-800 mb-1">{member.name}</h4>
                                <p className="text-md font-medium text-gray-600 mb-2">{member.role}</p>
                                <p className="text-md text-gray-600">{member.description}</p>
                            </div>
                            <div className="flex space-x-4">
                                {member.linkedin && <a href={member.linkedin} className="text-blue-600 hover:text-blue-800"><FaLinkedin size={20} /></a>}
                                {member.twitter && <a href={member.twitter} className="text-blue-400 hover:text-blue-600"><FaTwitter size={20} /></a>}
                                {member.github && <a href={member.github} className="text-gray-700 hover:text-gray-900"><FaGithub size={20} /></a>}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Teams;
