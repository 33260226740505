import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import int from '../images/int.mp4'; // Replace with your video file path
import { FaPlay, FaPause, FaCog, FaUsers, FaSync } from 'react-icons/fa';

const Intro = () => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(true);
    const [progress, setProgress] = useState(0);

    // Function to toggle video play/pause
    const togglePlay = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
                setIsPlaying(true);
            } else {
                videoRef.current.pause();
                setIsPlaying(false);
            }
        }
    };

    // Function to update progress bar
    const handleTimeUpdate = () => {
        if (videoRef.current) {
            setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
        }
    };

    // Function to handle progress bar click
    const handleProgressClick = (e) => {
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const newTime = (x / rect.width) * videoRef.current.duration;
        videoRef.current.currentTime = newTime;
        setProgress((videoRef.current.currentTime / videoRef.current.duration) * 100);
    };

    return (
        <div className="flex flex-col md:flex-row items-center justify-center h-screen p-4 md:p-12 bg-white-100">
            {/* Video Section */}
            <div className="w-full md:w-1/2 relative">

                <video
                    ref={videoRef}
                    className="w-full h-full object-cover rounded-lg shadow-lg"
                    muted
                    loop
                    playsInline
                    controls
                >
                    <source src={int} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

            </div>

            {/* Text Section */}
            <div className="w-full md:w-1/2 p-6 flex flex-col items-center justify-center text-center md:text-left">
                <h1 className="mb-5 md:text-6xl text-4xl font-extrabold text-blue-900 leading-tight">
                    Transform Your Future with GGIT
                </h1>
                <p className="text-lg mb-6 text-gray-600">
                    Seamless digital solutions, strategic expertise, and tailored support for public and non-profit sectors.
                </p>
                <div className="flex flex-col items-center space-y-4 md:space-y-0 md:flex-row md:space-x-6">
                    <div className="flex items-center space-x-2">
                        <FaCog className="text-blue-600" />
                        <span className="text-gray-700">Strategic Consulting</span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <FaUsers className="text-blue-600" />
                        <span className="text-gray-700">Tailored Solutions</span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <FaSync className="text-blue-600" />
                        <span className="text-gray-700">Seamless Integration</span>
                    </div>
                </div>
                <Link
                    to="/learn-more"
                    className="bg-blue-600 text-white py-2 px-6 rounded-lg text-lg font-semibold hover:bg-blue-500 mt-6"
                >
                    Learn More
                </Link>
            </div>
        </div>
    );
};

export default Intro;
