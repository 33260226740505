import React from 'react';
import { FaBriefcase, FaSchool, FaMoneyBillWave, FaCalendarAlt, FaBuilding, FaMobileAlt, FaTools, FaCoins } from 'react-icons/fa';

const Portfolio = () => {
    return (
        <div className="my-12 py-12 bg-gradient-to-r from-blue-50 to-blue-100" id="portfolio">
            <h2 className="my-6 text-center text-4xl text-blue-900 uppercase font-bold tracking-wide">Our Products</h2>
            <div className="flex justify-center mb-8">
                <div className="w-32 border-b-4 border-blue-900"></div>
            </div>

            <div className="px-4" data-aos="fade-up" data-aos-delay="400">
                <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                    {[
                        {
                            icon: <FaBriefcase />,
                            title: "Business Management System",
                            description: "Streamline operations and drive growth with our scalable Business Management System. Features include employee management, finance control, and cloud-based data access."
                        },
                        {
                            icon: <FaSchool />,
                            title: "School Management Portal",
                            description: "Manage educational institutions with our comprehensive School Management Portal, including features for student enrollment, attendance, grades, and communication."
                        },
                        {
                            icon: <FaMoneyBillWave />,
                            title: "Payroll Management System",
                            description: "Automate employee payments and enhance accuracy with our Payroll Management System. Seamlessly integrates into existing systems with robust APIs."
                        },
                        {
                            icon: <FaCalendarAlt />,
                            title: "Event Management System",
                            description: "Manage and customize events effortlessly with our Event Management System. Suitable for a range of events, ensuring a seamless experience."
                        },
                        {
                            icon: <FaCoins />,
                            title: "CollectEase FinTech",
                            description: "Facilitate peer-to-peer money collection with fair attribution in socially connected groups."
                        },
                        {
                            icon: <FaBuilding />,
                            title: "Real Estate Solutions",
                            description: "Offer innovative solutions for property management, including rental, sales, and market analysis."
                        },
                        {
                            icon: <FaMobileAlt />,
                            title: "SmartProperty Management",
                            description: "Provide an intelligent platform for property management, streamlining daily operations and improving process efficiency."
                        },
                        {
                            icon: <FaTools />,
                            title: "TechEase Solutions",
                            description: "Make technology accessible to everyone with practical solutions that enhance everyday life."
                        }
                    ].map((item, index) => (
                        <div key={index} className="bg-white transition-transform transform hover:scale-105 hover:shadow-xl overflow-hidden text-gray-700 rounded-lg shadow-md p-6 flex flex-col border border-gray-300 hover:border-blue-500">
                            <div className="flex justify-center mb-4">
                                <div className="text-blue-600 text-5xl">{item.icon}</div>
                            </div>
                            <h4 className="font-semibold text-xl text-gray-800 text-center mb-2">{item.title}</h4>
                            <p className="text-md font-medium text-gray-600 flex-1 mb-4">{item.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Portfolio;
