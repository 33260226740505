import React, { useState, useEffect } from 'react';
import NavLinks from '../Navbar/NavLinks';
import { HashLink } from 'react-router-hash-link';
import logo from '../../images/logo.png'; // Adjust the path as necessary

const NavBar = () => {
    const [top, setTop] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const scrollHandler = () => {
            window.pageYOffset > 10 ? setTop(false) : setTop(true);
        };
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    }, []);

    return (
        <nav className={`fixed top-0 w-full z-30 transition-all duration-300 ease-in-out ${!top ? 'bg-white shadow-md backdrop-blur-sm bg-opacity-80' : 'bg-transparent'}`}>
            <div className="flex flex-row justify-between items-center py-4 px-6 lg:px-12">
                <div className="flex items-center">
                    <HashLink smooth to="/#hero">
                        <img
                            src={logo}
                            alt="Logo"
                            className="h-16 w-auto"
                        />
                    </HashLink>
                </div>
                <div className="relative flex items-center">
                    <button className="p-2 rounded-lg lg:hidden text-blue-900 focus:outline-none" onClick={handleClick}>
                        <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            {isOpen ? (
                                <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                            ) : (
                                <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                            )}
                        </svg>
                    </button>
                    <div className="hidden lg:flex space-x-6">
                        <NavLinks />
                    </div>
                    <div className={`fixed top-0 left-0 w-full h-screen bg-white bg-opacity-95 shadow-lg transition-transform duration-300 ease-in-out lg:hidden ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
                        <div className="flex flex-col space-y-6 p-8">
                            <button className="p-2 rounded-lg text-blue-900 focus:outline-none mb-4" onClick={handleClick}>
                                <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                                </svg>
                            </button>
                            <NavLinks />
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
